@import "variables/colour";
@import "variables/font";
@import "variables/layout";
@import "functions/colour";
@import "functions/units";
@import "mixins/layout";

.step-indicator{
    width: 100%;
    padding-right: rem(24px);
    display: flex;
    align-content: stretch;
    flex-flow: row wrap;
    margin-bottom: $space-m;

    &__step{
        display: flex;
        flex: 1;
        flex-basis: auto;
        align-items: center;        
        height: rem(76px);
        min-width: rem(80px);
        position: relative;
        background: shade($primary-colour, 50);
        padding-left: rem(27px);
        margin-bottom: $space-s;
        cursor: default;
        border: none;

        &:hover,
        &:active,
        &:focus{
            border: none;
        }

        &__number{
            font-family: $header-font-family;
            color: transparentize($white, 0.3);
            font-size: rem(36px);
            height: rem(50px);
            width: rem(50px);
            min-width: rem(50px);            
            line-height: rem(25px);
            background: transparentize(tint($primary-colour, 20), 0.5);
            border-radius: rem(25px);
            display: flex;
            align-items: center;
            justify-content: center;

            &::before,
            &::after{
                content: " ";
                position: absolute;
                left: 100%;
                width: rem(35px);
                height: rem(38px); 
                opacity: 1 !important;               
                z-index: 998;

                @include mq($max: tablet-small){
                    height: rem(35px);
                    width: rem(38px);                    
                }                
            }
            &::before{
                background: linear-gradient(65deg, #000, rgba(0, 0, 0, 0.2) 15px, transparent 23px);
                top: 0;
                @include mq($max: tablet-small){
                    background: linear-gradient(205deg, #000, rgba(0, 0, 0, 0.2) 15px, transparent 23px);                    
                    top: 100%;
                    left: 0;
                }                
            }

            &::after{
                background: linear-gradient(115deg, #000, rgba(0, 0, 0, 0.2) 15px, transparent 23px);
                top: rem(38px);
                @include mq($max: tablet-small){
                    background: linear-gradient(155deg, #000, rgba(0, 0, 0, 0.2) 15px, transparent 23px);                    
                    top: 100%;
                    left: 50%;
                }                
            }

            @include mq($max: tablet-small){
                height: rem(35px);
                width: rem(35px);
                min-width: rem(35px);                
                font-size: rem(24px);
                line-height: rem(35px);
                margin-left: rem(20px);
            }                 
        }

        &__label{
            color: $white;
            padding-left: $space-s;
            text-decoration: none;
            border-bottom: none;
            vertical-align: middle;
            font-family: $header-font-family;

            @include mq($max: tablet-small){
                color: $text-colour;
                font-weight: bold;
                width: 300%;
                margin-left: rem(24px);
                flex-shrink: 0;
            }
        }

        &::after{
            border-left: rem(18px) solid shade($primary-colour, 50);
            border-top: rem(38px) solid transparent;
            border-bottom: rem(38px) solid transparent;
            border-right: none;
            content: " ";
            height: 0;
            position: absolute;
            left: 100%;
            top: 0;
            width: 0;
            z-index: 999;

            @include mq($max: tablet-small){
                border-top: rem(18px) solid shade($primary-colour, 50);
                border-left: rem(38px) solid transparent;
                border-right: rem(38px) solid transparent;
                border-bottom: none;
                left: 0;
                top: 100%;
            }
        }

        &.navigatable{
            cursor: pointer;
        }

        &.is-active{
            .step-indicator__step__number{
                color: $white;
                background: tint($primary-colour, 20);                
            }
        }

        &.hide-label{
            .step-indicator__step__label{
                display: none;
            }
        }

        @include mq($max: tablet-small){
            height: rem(76px);
            min-width: rem(76px);
            width: rem(76px);
            padding-left: 0;
            padding-top: rem(30px);
            margin-bottom: 0;

            &:first-child{
                padding-top: rem(18px);                
                height: rem(70px);
            }
        }
    }
    
    @include mq($max: tablet-small){
        flex-flow: column
    }                    
}

@for $i from 0 through 4{
    $selector: '.step-indicator__step';
    $stepColour: shade($primary-colour, $i*10 + 20);
    $childSelector: null;
    @if ($i > 0){
        @for $j from 1 through ($i){
            $childSelector: #{$childSelector} + #{$selector};
        }
    }
    
    #{$selector}:first-child #{$childSelector}{
        background: $stepColour;
        &::after{
            border-left-color: $stepColour;

            @include mq($max: tablet-small){
                border-left-color: transparent;
                border-top-color: $stepColour;
            }
        }
    }
}