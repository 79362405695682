@import "variables/colour";
@import "functions/colour";

// ------------------------------------------------------------------
//   Message styles
// ------------------------------------------------------------------
$message-error-message-colour:                  $red !default;
$message-error-message-border:                  1px solid $red !default;
$message-error-message-background-colour:       $curtin-ecru !default;
$message-error-inline-colour:                   $red !default;
$message-error-inline-border:                   none !default;
$message-error-inline-background-colour:        inherit !default;
$message-error-highlight-colour:                $red !default;
$message-error-highlight-border:                none !default;
$message-error-highlight-background-colour:     inherit !default;
$message-error-field-colour:                    $red !default;
$message-error-field-border:                    1px solid $red !default;
$message-error-field-background-colour:         $curtin-ecru !default;
$message-error-icon-colour:                     $red !default;    
$message-error-marker-border:                   3px solid $red !default;
$message-error-panel-header-colour:             $curtin-ecru !default;         
$message-error-panel-header-background-colour:  $red !default;

$message-success-message-colour:                $black85 !default;
$message-success-message-border:                1px solid $green !default;
$message-success-message-background-colour:     $curtin-ecru !default;
$message-success-inline-colour:                 $black85 !default;
$message-success-inline-border:                 none !default;
$message-success-inline-background-colour:      tint($green, 90) !default;
$message-success-highlight-colour:              $black85 !default;
$message-success-highlight-border:              none !default;
$message-success-highlight-background-colour:   tint($green, 90) !default;
$message-success-field-colour:                  inherit !default;
$message-success-field-border:                  1px solid $green !default;
$message-success-field-background-colour:       $white !default;
$message-success-icon-colour:                   $green !default;    
$message-success-marker-border:                 none !default;
$message-success-panel-header-colour:           $curtin-ecru !default;         
$message-success-panel-header-background-colour:$green !default;

$message-info-message-colour:                   $black85 !default;
$message-info-message-border:                   1px solid $blue !default;
$message-info-message-background-colour:        $curtin-ecru !default;
$message-info-inline-colour:                    $black85 !default;
$message-info-inline-border:                    none !default;
$message-info-inline-background-colour:         tint($blue, 90) !default;
$message-info-highlight-colour:                 $black85 !default;
$message-info-highlight-border:                 none !default;
$message-info-highlight-background-colour:      tint($blue, 90) !default;
$message-info-field-colour:                     inherit !default;
$message-info-field-border:                     1px solid $blue !default;
$message-info-field-background-colour:          $white !default;
$message-info-icon-colour:                      $blue !default;    
$message-info-marker-border:                    none !default;
$message-info-panel-header-colour:              $curtin-ecru !default;         
$message-info-panel-header-background-colour:   $blue !default;
   
$message-warning-message-colour:                $black85 !default;
$message-warning-message-border:                1px solid $yellow !default;
$message-warning-message-background-colour:     $curtin-ecru !default;
$message-warning-inline-colour:                 $black85 !default;
$message-warning-inline-border:                 none !default;
$message-warning-inline-background-colour:      inherit !default;
$message-warning-highlight-colour:              $black85 !default;
$message-warning-highlight-border:              none !default;
$message-warning-highlight-background-colour:   inherit !default;
$message-warning-field-colour:                  inherit !default;
$message-warning-field-border:                  1px solid $yellow !default;
$message-warning-field-background-colour:       $curtin-ecru !default;
$message-warning-icon-colour:                   $yellow !default;    
$message-warning-marker-border:                 3px solid $yellow !default;
$message-warning-panel-header-colour:           $black85 !default;         
$message-warning-panel-header-background-colour:$yellow !default;

$message-success-styles: (
    message-colour:                 $message-success-message-colour,
    message-border:                 $message-success-message-border,
    message-background-colour:      $message-success-message-background-colour,
    inline-colour:                  $message-success-inline-colour,
    inline-border:                  $message-success-inline-border,
    inline-background-colour:       $message-success-inline-background-colour,
    highlight-colour:               $message-success-highlight-colour,
    highlight-border:               $message-success-highlight-border,
    highlight-background-colour:    $message-success-highlight-background-colour,
    field-colour:                   $message-success-field-colour,
    field-border:                   $message-success-field-border,
    field-background-colour:        $message-success-field-background-colour,
    icon-colour:                    $message-success-icon-colour,
    marker-border:                  $message-success-marker-border,
    panel-header-colour:            $message-success-panel-header-colour,
    panel-header-background-colour: $message-success-panel-header-background-colour,
    icon:                           correct
) !default;

$message-info-styles: (
    message-colour:                 $message-info-message-colour,
    message-border:                 $message-info-message-border,
    message-background-colour:      $message-info-message-background-colour,
    inline-colour:                  $message-info-inline-colour,
    inline-border:                  $message-info-inline-border,
    inline-background-colour:       $message-info-inline-background-colour,
    highlight-colour:               $message-info-highlight-colour,
    highlight-border:               $message-info-highlight-border,
    highlight-background-colour:    $message-info-highlight-background-colour,
    field-colour:                   $message-info-field-colour,
    field-border:                   $message-info-field-border,
    field-background-colour:        $message-info-field-background-colour,
    icon-colour:                    $message-info-icon-colour,
    marker-border:                  $message-info-marker-border,
    panel-header-colour:            $message-info-panel-header-colour,
    panel-header-background-colour: $message-info-panel-header-background-colour,
    icon:                           info
) !default;

$message-warning-styles: (
    message-colour:                 $message-warning-message-colour,
    message-border:                 $message-warning-message-border,
    message-background-colour:      $message-warning-message-background-colour,
    inline-colour:                  $message-warning-inline-colour,
    inline-border:                  $message-warning-inline-border,
    inline-background-colour:       $message-warning-inline-background-colour,
    highlight-colour:               $message-warning-highlight-colour,
    highlight-border:               $message-warning-highlight-border,
    highlight-background-colour:    $message-warning-highlight-background-colour,
    field-colour:                   $message-warning-field-colour,
    field-border:                   $message-warning-field-border,
    field-background-colour:        $message-warning-field-background-colour,
    icon-colour:                    $message-warning-icon-colour,
    marker-border:                  $message-warning-marker-border,
    panel-header-colour:            $message-warning-panel-header-colour,
    panel-header-background-colour: $message-warning-panel-header-background-colour,
    icon:                           warning
) !default;

$message-error-styles: (
    message-colour:                 $message-error-message-colour,
    message-border:                 $message-error-message-border,
    message-background-colour:      $message-error-message-background-colour,
    inline-colour:                  $message-error-inline-colour,
    inline-border:                  $message-error-inline-border,
    inline-background-colour:       $message-error-inline-background-colour,
    highlight-colour:               $message-error-highlight-colour,
    highlight-border:               $message-error-highlight-border,
    highlight-background-colour:    $message-error-highlight-background-colour,
    field-colour:                   $message-error-field-colour,
    field-border:                   $message-error-field-border,
    field-background-colour:        $message-error-field-background-colour,
    icon-colour:                    $message-error-icon-colour,
    marker-border:                  $message-error-marker-border,
    panel-header-colour:            $message-error-panel-header-colour,
    panel-header-background-colour: $message-error-panel-header-background-colour,
    icon:                           error
) !default;

$message-types: (
    success: $message-success-styles,
    info: $message-info-styles,
    warning: $message-warning-styles,
    error: $message-error-styles,) !default;