@import "variables/colour";
@import "functions/units";

// ------------------------------------------------------------------
//   Buttons
// ------------------------------------------------------------------
$button-font-family:    "GandhiSansBold", Arial !default;
$button-font-size:      rem(15px) !default;
$button-radius:         3px !default;
$button-padding:        1.25em !default;
$button-template:       '%button' !default;

// ------------------------------------------------------------------
//   Default button styling
// ------------------------------------------------------------------
$button-colour: $purple !default;
$button-background-colour: $black10 !default;
$button-background-image: linear-gradient(to top, $black10 0%, $grey15 45.12%, $grey10 49.19%, $white 100%) !default;
$button-border-colour: $grey80 !default;
$button-box-shadow: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px $white !default;
$button-text-shadow: none !default;

$button-colour-hover: tint($purple, 20) !default;
$button-background-colour-hover: $black5 !default;
$button-background-image-hover: linear-gradient(to top, $black10 0%, $grey15 45.12%, $grey10 49.19%, $white 100%) !default;
$button-border-colour-hover: $yellow !default;
$button-box-shadow-hover: inset 0 0 0 1px $white !default;

$button-colour-active: $button-colour !default;
$button-background-colour-active: $black15 !default;
$button-background-image-active: linear-gradient(to top, $grey15 0%, $grey10 41.5%, $grey5 54.88%, $grey30 100%) !default;
$button-border-colour-active: $button-border-colour-hover !default;
$button-box-shadow-active: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px $white !default;


// ------------------------------------------------------------------
//   Primary button styling
// ------------------------------------------------------------------
$button-colour--primary: $black80 !default;
$button-background-colour--primary: $yellow !default;
$button-background-image--primary: linear-gradient(to top, #f1b500 0%, $yellow 48.24%, #ffc925 52.25%, #ffcf3f 100%) !default;
$button-border-colour--primary: #cb9900 !default;
$button-box-shadow--primary: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255,.45) !default;
$button-text-shadow--primary: white(0.5) 0 1px 0 !default;

$button-colour-hover--primary: #333 !default;
$button-background-colour-hover--primary: $yellow80 !default;
$button-background-image-hover--primary: linear-gradient(to top, #ffc925 0%, #ffc925 45.12%, #ffcf3f 49.19%, #ffd965 100%) !default;
$button-border-colour-hover--primary: $yellow !default;
$button-box-shadow-hover--primary: $button-box-shadow--primary !default;

$button-colour-active--primary: #333 !default;
$button-background-colour-active--primary: shade($yellow, 5) !default;
$button-background-image-active--primary: linear-gradient(to top, #ffd14b 0%, #ffd965 41.5%, #ffd14b 54.88%, #ffc925 100%) !default;
$button-border-colour-active--primary: $button-border-colour--primary !default;
$button-box-shadow-active--primary: $button-box-shadow--primary !default;

// ------------------------------------------------------------------
//   Secondary button styling
// ------------------------------------------------------------------
$button-colour--secondary: $white !default;
$button-background-colour--secondary: #cb4500 !default;
$button-background-image--secondary: linear-gradient(to top, #c14100 0%, #cb4500 45%, #d15719 54%, #d36125 100%) !default;
$button-border-colour--secondary: #cb4500 !default;
$button-box-shadow--secondary: 0 2px 2px rgba(0,0,0,.06), inset 0 0 0 1px rgba(255,255,255,.35) !default;
$button-text-shadow--secondary: 0 -1px 0 rgba(0,0,0,0.4) !default;

$button-colour-hover--secondary: $button-colour--secondary !default;
$button-background-colour-hover--secondary: #993400 !default;
$button-background-image-hover--secondary: linear-gradient(to top, #ae3c00 0%, #ae3c00 45%, #c24200 54%, #cc4600 100%) !default;
$button-border-colour-hover--secondary: #993400 !default;
$button-box-shadow-hover--secondary: $button-box-shadow--secondary !default;

$button-colour-active--secondary: $button-colour--secondary !default;
$button-background-colour-active--secondary: $button-background-colour--secondary !default;
$button-background-image-active--secondary: linear-gradient(to top, #d36125 0%, #d56b33 45%, #d36125 54%, #cb4500 100%) !default;
$button-border-colour-active--secondary: $button-border-colour--secondary !default;
$button-box-shadow-active--secondary: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45) !default;

// ------------------------------------------------------------------
//   Dark button styling
// ------------------------------------------------------------------
$button-colour--dark: $white !default;
$button-background-colour--dark: $black80 !default;
$button-background-image--dark: linear-gradient(to top, $black 0%, #191919 49.76%, #252525 53.76%, #333 100%) !default;
$button-border-colour--dark: $black !default;
$button-box-shadow--dark: 0 2px 2px rgba(0,0,0,.06), inset 0 0 0 1px rgba(255,255,255,.13) !default;
$button-text-shadow--dark: 0 -1px 0 rgba(0, 0, 0, 1) !default;

$button-colour-hover--dark: $yellow !default;
$button-background-colour-hover--dark: $black70 !default;
$button-background-image-hover--dark: linear-gradient(to top, #0d0d0d 0%, #252525 49.76%, #333 53.76%, #3f3f3f 100%) !default;
$button-border-colour-hover--dark: $button-border-colour--dark !default;
$button-box-shadow-hover--dark: $button-box-shadow--dark !default;

$button-colour-active--dark: $button-colour--dark !default;
$button-background-colour-active--dark: $black95 !default;
$button-background-image-active--dark: linear-gradient(to top, #252525 0%, #252525 37.74%, #252525 47%, $black 100%) !default;
$button-border-colour-active--dark: $button-border-colour--dark !default;
$button-box-shadow-active--dark: $button-box-shadow--dark !default;

// ------------------------------------------------------------------
//   Primary submit button styling
// ------------------------------------------------------------------
$button-colour--primary-submit: $white !default;
$button-background-colour--primary-submit: $green !default;
$button-background-image--primary-submit: linear-gradient(to top, shade($green, 5) 0%, $green 50.76%, #238d0d 54.25%, #238d0d 100%) !default;
$button-border-colour--primary-submit: $green !default;
$button-box-shadow--primary-submit: 0 2px 2px rgba(0, 0, 0, .06), inset 0 0 0 1px rgba(255, 255, 255, .45) !default;
$button-text-shadow--primary-submit: 0 -1px 0 rgba(0, 0, 0, .4) !default;

$button-colour-hover--primary-submit: $button-colour--primary-submit !default;
$button-background-colour-hover--primary-submit: tint($green, 15) !default;
$button-background-image-hover--primary-submit: linear-gradient(to top, #137300 0%, #157b00 50.76%, #158100 54.25%, $green 100%) !default;
$button-border-colour-hover--primary-submit: #ffbf00 !default;
$button-box-shadow-hover--primary-submit: $button-box-shadow--primary-submit !default;

$button-colour-active--primary-submit: $button-colour--primary-submit !default;
$button-background-colour-active--primary-submit: shade($green, 15) !default;
$button-background-image-active--primary-submit: linear-gradient(to top, #238d0d 0%, #2d9319 45.12%, #2d9319 49.19%, #158100 100%) !default;
$button-border-colour-active--primary-submit: $button-border-colour--primary-submit !default;
$button-box-shadow-active--primary-submit: $button-box-shadow--primary-submit !default;

// ------------------------------------------------------------------
//   Secondary submit button styling
// ------------------------------------------------------------------
$button-colour--secondary-submit: shade($grey, 50) !default;
$button-background-colour--secondary-submit: $button-background-colour !default;
$button-background-image--secondary-submit: $button-background-image !default;
$button-border-colour--secondary-submit: $button-border-colour !default;
$button-box-shadow--secondary-submit: $button-box-shadow !default;
$button-text-shadow--secondary-submit: $button-text-shadow !default;

$button-colour-hover--secondary-submit: $button-colour--secondary-submit !default;
$button-background-colour-hover--secondary-submit: $button-background-colour-hover !default;
$button-background-image-hover--secondary-submit: $button-background-image-hover !default;
$button-border-colour-hover--secondary-submit: $button-border-colour-hover !default;
$button-box-shadow-hover--secondary-submit: $button-colour--secondary-submit !default;

$button-colour-active--secondary-submit: $button-colour--secondary-submit !default;
$button-background-colour-active--secondary-submit: $button-background-colour-active !default;
$button-background-image-active--secondary-submit: $button-background-image-active !default;
$button-border-colour-active--secondary-submit: $button-border-colour--secondary-submit !default;
$button-box-shadow-active--secondary-submit: $button-box-shadow-active !default;

// ------------------------------------------------------------------
//   Button variable maps
// ------------------------------------------------------------------
$button-default: (
    colour: $button-colour,
    background-colour: $button-background-colour,
    background-image: $button-background-image,
    border-colour: $button-border-colour,
    box-shadow: $button-box-shadow,
    text-shadow: $button-text-shadow,

    colour-hover: $button-colour-hover,
    background-colour-hover: $button-background-colour-hover,
    background-image-hover: $button-background-image-hover,
    border-colour-hover: $button-border-colour-hover,
    box-shadow-hover: $button-box-shadow-hover,

    colour-active: $button-colour-active,
    background-colour-active: $button-background-colour-active,
    background-image-active: $button-background-image-active,
    border-colour-active: $button-border-colour-active,
    box-shadow-active: $button-box-shadow-active,
) !default;

$button-primary: (
    colour: $button-colour--primary,
    background-colour: $button-background-colour--primary,
    background-image: $button-background-image--primary,
    border-colour: $button-border-colour--primary,
    box-shadow: $button-box-shadow--primary,
    text-shadow: $button-text-shadow--primary,

    colour-hover: $button-colour-hover--primary,
    background-colour-hover: $button-background-colour-hover--primary,
    background-image-hover: $button-background-image-hover--primary,
    border-colour-hover: $button-border-colour-hover--primary,
    box-shadow-hover: $button-box-shadow-hover--primary,

    colour-active: $button-colour-active--primary,
    background-colour-active: $button-background-colour-active--primary,
    background-image-active: $button-background-image-active--primary,
    border-colour-active: $button-border-colour-active--primary,
    box-shadow-active: $button-box-shadow-active--primary,
) !default;

$button-secondary: (
    colour: $button-colour--secondary,
    background-colour: $button-background-colour--secondary,
    background-image: $button-background-image--secondary,
    border-colour: $button-border-colour--secondary,
    box-shadow: $button-box-shadow--secondary,
    text-shadow: $button-text-shadow--secondary,

    colour-hover: $button-colour-hover--secondary,
    background-colour-hover: $button-background-colour-hover--secondary,
    background-image-hover: $button-background-image-hover--secondary,
    border-colour-hover: $button-border-colour-hover--secondary,
    box-shadow-hover: $button-box-shadow-hover--secondary,

    colour-active: $button-colour-active--secondary,
    background-colour-active: $button-background-colour-active--secondary,
    background-image-active: $button-background-image-active--secondary,
    border-colour-active: $button-border-colour-active--secondary,
    box-shadow-active: $button-box-shadow-active--secondary,
) !default;

$button-dark: (
    colour: $button-colour--dark,
    background-colour: $button-background-colour--dark,
    background-image: $button-background-image--dark,
    border-colour: $button-border-colour--dark,
    box-shadow: $button-box-shadow--dark,
    text-shadow: $button-text-shadow--dark,

    colour-hover: $button-colour-hover--dark,
    background-colour-hover: $button-background-colour-hover--dark,
    background-image-hover: $button-background-image-hover--dark,
    border-colour-hover: $button-border-colour-hover--dark,
    box-shadow-hover: $button-box-shadow-hover--dark,

    colour-active: $button-colour-active--dark,
    background-colour-active: $button-background-colour-active--dark,
    background-image-active: $button-background-image-active--dark,
    border-colour-active: $button-border-colour-active--dark,
    box-shadow-active: $button-box-shadow-active--dark,
) !default;

$button-primary-submit: (
    colour: $button-colour--primary-submit,
    background-colour: $button-background-colour--primary-submit,
    background-image: $button-background-image--primary-submit,
    border-colour: $button-border-colour--primary-submit,
    box-shadow: $button-box-shadow--primary-submit,
    text-shadow: $button-text-shadow--primary-submit,

    colour-hover: $button-colour-hover--primary-submit,
    background-colour-hover: $button-background-colour-hover--primary-submit,
    background-image-hover: $button-background-image-hover--primary-submit,
    border-colour-hover: $button-border-colour-hover--primary-submit,
    box-shadow-hover: $button-box-shadow-hover--primary-submit,

    colour-active: $button-colour-active--primary-submit,
    background-colour-active: $button-background-colour-active--primary-submit,
    background-image-active: $button-background-image-active--primary-submit,
    border-colour-active: $button-border-colour-active--primary-submit,
    box-shadow-active: $button-box-shadow-active--primary-submit,
) !default;

$button-secondary-submit: (
    colour: $button-colour--secondary-submit,
    background-colour: $button-background-colour--secondary-submit,
    background-image: $button-background-image--secondary-submit,
    border-colour: $button-border-colour--secondary-submit,
    box-shadow: $button-box-shadow--secondary-submit,
    text-shadow: $button-text-shadow--secondary-submit,

    colour-hover: $button-colour-hover--secondary-submit,
    background-colour-hover: $button-background-colour-hover--secondary-submit,
    background-image-hover: $button-background-image-hover--secondary-submit,
    border-colour-hover: $button-border-colour-hover--secondary-submit,
    box-shadow-hover: $button-box-shadow-hover--secondary-submit,

    colour-active: $button-colour-active--secondary-submit,
    background-colour-active: $button-background-colour-active--secondary-submit,
    background-image-active: $button-background-image-active--secondary-submit,
    border-colour-active: $button-border-colour-active--secondary-submit,
    box-shadow-active: $button-box-shadow-active--secondary-submit,
) !default;

$button-types: (
    default: $button-default, 
    primary: $button-primary, 
    secondary: $button-secondary, 
    dark: $button-dark, 
    primary-submit: $button-primary-submit, 
    secondary-submit: $button-secondary-submit
) !default;